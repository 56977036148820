import { render, staticRenderFns } from "./leftSection.vue?vue&type=template&id=188b1c8e"
import script from "./leftSection.vue?vue&type=script&lang=js"
export * from "./leftSection.vue?vue&type=script&lang=js"
import style0 from "./leftSection.vue?vue&type=style&index=0&id=188b1c8e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports